import {Outlet, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import Header from "../../components/header/Header";

export default function HomeSteps(){
    const navigate = useNavigate();
    let [data, setData] = useState([
        {
            flag: false,
        }
    ])

    // useEffect(() => {
    //     navigate('./_1');
    // }, []);

    return (
        <div className="main">
            <Header/>
            <Outlet data={data} setData={setData}/>
        </div>
    )
}