import "./style.css";
import Header from "../../components/header/Header";
import home_back from "../../assets/images/home_background_image.png";

export default function About(){
    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="home_cont">
                    About
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}