import Header from "../../components/header/Header";
import login_pic from "../../assets/images/login_pic.png";
import mail_icon from "../../assets/images/icons/mail_icon.png";
import block_icon from "../../assets/images/icons/block_icon.png";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import config from "../../config";
import axios from "axios";

export default function ForgotPassword() {
    let navigate = useNavigate();
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");
    let [show, setShow] = useState(false)
    const [forgotPass, setForgotPass] = useState({
        email: "",
    })
    const [error, setError] = useState("")
    const [forgotPassBtn, setForgotPassBtn] = useState(false)
    const handleChange = (e, name) => {
        setForgotPass({...forgotPass, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(forgotPass.email).toLowerCase());
    }
    const ForgotPassHandler = () => {
        let values={
            email: forgotPass.email,
        }
        setForgotPassBtn(true)
        if (forgotPass.email.length) {
                if (validateEmail()) {
                    axios.post(`${config.url}/v1/password_reset/`,values )
                        .then(response => {
                            console.log(response,"response forgot");
                            localStorage.setItem('jwtToken',  response.data.token)
                            navigate("/login/reset_password")
                        })
                        .catch(error => {
                            console.log(error.response,"error forgot")
                            setError(error.response.statusText)
                        })
                } else {
                    setError("Incorrect email address")
        }
        }
    }


    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="Login_cont d_flex align_items_center justify_content_space_between">
                    <div className="Login_cont_b1">
                        <img src={login_pic} alt="pic" className="login_pic"/>
                    </div>
                    <div className="Login_cont_b2 d_flex fd_column">
                        <p className="font_Canela fs_55 f_700 text_left">Forgot Password</p>
                        <div className="d_flex fd_column inputs_b">
                            <label htmlFor="" className="text_left"
                            style={{marginBottom:"15px"}}>Email</label>
                            <div className="input_b">
                                <img src={mail_icon} alt="mail_icon"/>
                                <input type="email" placeholder="Email Address"
                                       style={!forgotPass.email && forgotPassBtn ? {border: "1px solid red"} : null}
                                       onChange={(e) => handleChange(e.target.value, "email")}
                                />
                            </div>
                        </div>
                        {error ? <p className="error">{error}</p> : null}
                        <div className="bc_green login_btn_b">
                            <button className="fs_22 f_600 c_white bc_green"  onClick={ForgotPassHandler}>Forgot Password</button>
                        </div>
                    </div>

                </div>
            </div>
            <div>

            </div>
        </div>
    )
}