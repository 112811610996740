import "./style.css";
import Header from "../../components/header/Header";
import home_back from "../../assets/images/home_background_image.png";
import {useNavigate} from "react-router-dom";

export default function Home() {
    let  navigate=useNavigate()
    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="home_cont">
                    <img src={home_back} alt="photo" className="home_back"/>
                    <div className="home_content">
                        <div className="home_content_inner d_flex fd_column justify_content_start align_items_start">
                            <h1 className="font_Canela fs_55 c_white f_700 text_left">Vet care & prescriptions from
                                the comfort of home</h1>
                            <p className="fs_22 f_500 c_white text_left">Need a prescription or have an urgent concern? Let’s resolve your issue now.</p>
                            <div className="get_started_btn_b bc_green" onClick={()=>navigate("/login")}>
                                <button className="fs_22 f_600 c_white bc_green">Get Started</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}