import "./style.css";
import Header from "../../../components/header/Header";
import back_pic from "../../../assets/images/HomePage_pic.png";
import Union from "../../../assets/images/Union.png";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import back_pic_cat from "../../../assets/images/HomePage_pic_back_cat.png";

export default function HomeStep4() {
    let navigate=useNavigate()
    let {state}=useLocation()
    const [boxActive, setBoxActive] = useState(0)
    let [boxBtn,setBoxBtn]=useState([
        {
            title:"Male",
            id: 1,
        },
        {
            title:"Female",
            id: 2,
        },
    ])
    let _handleContinue = (index) => {
        setBoxActive(index)
            navigate("/home/_5", {
                state: {
                    animal_name: state.animal_name,
                    img_key: state.img_key ? 'cat' : 'dog',
                }
            })
            console.log("next step")
        }

    return (
        <div className="home_cont">
            {state.img_key === 'dog' ?
                <img src={back_pic} alt="photo" className="home_back"/>
                :
                <img src={back_pic_cat} alt="photo" className="home_back"/>
            }
            <div className="home_page2_container">
                <div className="container">
                    <div className="container_inner">
                        <div className="home_page_content">
                            <div className="d_flex fd_column align_items_start justify_content_start">
                                {/*<div className="d_flex fd_column align_items_start">*/}
                                {/*    <div className="d_flex align_items_center">*/}
                                {/*        <p className="fs_55 font_Slackey c_brown"> Our online vets</p>*/}
                                {/*        <img src={Union} alt="union"/>*/}
                                {/*    </div>*/}
                                {/*    <p className="fs_55 font_Slackey c_brown">are here to help</p>*/}
                                {/*</div>*/}
                                <p className="fs_55 font_Canela c_brown home_page_content_p">
                                    Is {state.animal_name} male or female?
                                </p>
                                <div className="d_flex fd_column justify_content_center align_items_center home_page_content_span">
                                    <span className="text_center fs_18 f_500">If you're not sure or if they're a mix, choose mixed breed below.</span>
                                </div>

                                <div className="d_flex align_items_center pet_btns_b justify_content_space_between">
                                    {
                                        boxBtn.map((item,index)=>{
                                            return(
                                                <>
                                                    <button className={boxActive===index ? "pet_btn_active fs_22 f_600" :"pet_btn fs_22 f_400"}
                                                            onClick={(index)=> {
                                                                _handleContinue(index)
                                                            }

                                                            }>{item.title}</button>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}