import "./style.css";
import back_pic from "../../../assets/images/HomePage_pic.png";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import back_pic_cat from "../../../assets/images/HomePage_pic_back_cat.png";

export default function HomeStep3(props) {
    let navigate = useNavigate()
    let {state} = useLocation()
    const [boxActive, setBoxActive] = useState(0)
    console.log(state, "yrtytry")
    const options = [

        {
            value: '',
            text: 'Choose an bread',
            id: 0,
        },
        {
            value: 'American Bobtail',
            text: 'American Bobtail',
            id: 1,
        },
        {
            value: 'Kurilian Bobtail',
            text: 'Kurilian Bobtail',
            id: 2,
        },
    ];
    let [petBread, setPetBread] = useState(options[0].value)
    const [error, setError] = useState("")

    const handleChange = event => {
        console.log(event.target.value);
        setPetBread(event.target.value);
        setError("")
    };

    let _handleContinue = () => {
        if (petBread.length) {
            navigate("/home/_4", {
                state: {
                    animal_name: state.animal_name,
                    img_key: state.img_key  ? 'cat' : 'dog',
                }
            })
            console.log("next step")
        } else {
            setError("Please fill pet's name")
        }

    }
    console.log(state);
    return (
        <div className="home_cont">
            {state.img_key === 'dog' ?
                <img src={back_pic} alt="photo" className="home_back"/>
                :
                <img src={back_pic_cat} alt="photo" className="home_back"/>
            }
            <div className="home_page2_container">
                <div className="container">
                    <div className="container_inner">
                        <div className="home_page_content">
                            <div className="d_flex fd_column">
                                <p className="fs_55 font_Canela c_brown home_page_content_p d_flex align_items_center">
                                    What breed is {state.animal_name}?
                                </p>
                                <div
                                    className="d_flex fd_column justify_content_center align_items_center home_page_content_span">
                                    <span className="text_center fs_18 f_500">If you're not sure or if they're a mix, choose mixed breed below..</span>
                                </div>
                                <div className="home_page_content_input_b">
                                    <select value={petBread} className="fs_22 c_blue" onChange={handleChange}>
                                        {
                                            options.map((option, index) => {
                                                return (
                                                    <option key={option.value}
                                                            value={option.value}>{option.text}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                {error ? <p className="error text_center">{error}</p> : null}
                                <div className="d_flex align_items_center justify_content_center continue_btn_b"
                                     onClick={_handleContinue}>
                                    <button className="c_white fs_22 f_600">Continue</button>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}