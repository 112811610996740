import "./style.css";
import Header from "../../../components/header/Header";
import back_pic from "../../../assets/images/HomePage_pic.png";
import back_pic_cat from "../../../assets/images/HomePage_pic_back_cat.png";
import Union from "../../../assets/images/Union.png";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export default function HomeStep2({index, props}) {
    let navigate = useNavigate()
    let [name, setName] = useState("")
    const [error, setError] = useState("")
    const [boxActive, setBoxActive] = useState(0)
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");
    let [boxBtn, setBoxBtn] = useState([
        {
            title: "Dog",
            id: 1,
            flag: true,
        },
        {
            title: "Cat",
            id: 2,
            flag: false
        },
    ])

    let changeName = (e) => {
        setName(e.target.value)
        setError("")
    }

    let _handleContinue = () => {
        if (name.length) {
            navigate("/home/_3", {
                state: {
                    animal_name: name,
                    img_key: boxActive ? 'cat' : 'dog' ,
                }
            })
        } else {
            setError("Please fill pet's name")
        }

    }
    return (
        <div className="home_cont">
            {
                boxActive === 0 ?
                    <img src={back_pic} alt="photo" className="home_back"/>
                    :
                    <img src={back_pic_cat} alt="photo" className="home_back"/>
            }

            <div className="home_page2_container">
                <div className="container">
                    <div className="container_inner">
                        <div className="home_page_content">
                            <div className="d_flex fd_column">
                                <p className="fs_55 font_Canela c_brown home_page_content_p f_700">
                                    Get allergy & itch medication for your pet online
                                </p>
                                <div
                                    className="d_flex fd_column justify_content_center align_items_center home_page_content_span">
                                    <span className="d_flex justify_content_center text_center fs_18 f_500">Let’s get started</span>
                                    <span
                                        className="text_center fs_18 f_500">Select your pet type and enter their name.</span>
                                </div>

                                <div className="d_flex align_items_center pet_btns_b justify_content_space_between">
                                    {
                                        boxBtn.map((item, index) => {
                                            return (
                                                <>
                                                    <button
                                                        className={boxActive === index ? "pet_btn_active fs_22 f_600" : "pet_btn fs_22 f_400"}

                                                        onClick={(item) => {
                                                            setBoxActive(index)
                                                        }}>{item.title}</button>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                <div className="home_page_content_input_b">
                                    <input type="text" placeholder="Your pet's name" className="fs_22"
                                           onChange={changeName} value={name}/>
                                </div>
                                {error ? <p className="error text_center">{error}</p> : null}
                                <div className="d_flex align_items_center justify_content_center continue_btn_b"
                                     onClick={_handleContinue}>
                                    <button className="c_white fs_22 f_600">Continue</button>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}