import "./style.css";
import Header from "../../components/header/Header";
import login_pic from "../../assets/images/login_pic.png";
import user_icon from "../../assets/images/icons/user_icon.png";
import mail_icon from "../../assets/images/icons/mail_icon.png";
import block_icon from "../../assets/images/icons/block_icon.png";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import config from "../../config";
import axios from "axios";

export default function Register() {
    let navigate = useNavigate();
    let [show, setShow] = useState(false)
    const [register, setRegister] = useState({
        full_name:"",
        email: "",
        password: "",
    })
    const [error, setError] = useState("")
    const [registerBtn, setRegisterBtn] = useState(false)
    const handleChangeLogin = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(register.email).toLowerCase());
    }
    const RegisterHandler = () => {
        let values={
            full_name:register.full_name,
            email: register.email,
            password: register.password,
        }
        setRegisterBtn(true)
        if (register.full_name.length && register.email.length && register.password.length) {
            if (register.password.length > 7) {
                if (validateEmail()) {
                    axios.post(`${config.url}/v1/users/`,values )
                        .then(response => {
                            console.log(response,"response");
                            localStorage.setItem('jwtToken',  response.data.token)
                            navigate("/login")
                        })
                        .catch(error => {
                            console.log(error.response,"error")
                            setError(error.response.data.error)
                        })
                } else {
                    setError("Incorrect email address")
                }
            } else {
                setError("Password length is small")
            }
        }
    }


    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="Login_cont d_flex align_items_center justify_content_space_between">
                    <div className="Login_cont_b1">
                        <img src={login_pic} alt="pic" className="login_pic"/>
                    </div>
                    <div className="Login_cont_b2 d_flex fd_column">
                        <p className="font_Canela fs_55 f_700 text_left">Hello!</p>
                        <span className="fs_22 f_500 text_left title2">Sign Up to Get Started</span>
                        <div className="d_flex fd_column inputs_b">
                            <div className="input_b">
                                <img src={user_icon} alt="user_icon"/>
                                <input type="text" placeholder="Full Name"
                                       style={!register.full_name && registerBtn ? {border: "1px solid red"} : null}
                                       onChange={(e) => handleChangeLogin(e.target.value, "full_name")}
                                />
                            </div>
                            <div className="input_b margin_top_16">
                                <img src={mail_icon} alt="mail_icon"/>
                                <input type="email" placeholder="Email Address"
                                       style={!register.email && registerBtn ? {border: "1px solid red"} : null}
                                       onChange={(e) => handleChangeLogin(e.target.value, "email")}
                                />
                            </div>
                            <div className="input_b margin_top_16">
                                <img src={block_icon} alt="block_icon"/>
                                <input type="password" placeholder="Password"
                                       style={!register.password && registerBtn ? {border: "1px solid red"} : null}
                                       onChange={(e) => handleChangeLogin(e.target.value, "password")}
                                />
                            </div>
                            <div className="d_flex align_items_end justify_content_end margin_top_16 have_account" style={{marginRight:"0px"}}>
                                <div className=" ">Already have an account? <span onClick={()=>navigate("/login")} className="f_600">Login</span></div>
                            </div>
                        </div>
                        {error ? <p className="error">{error}</p> : null}
                        <div className="bc_green login_btn_b" onClick={RegisterHandler}>
                            <button className="fs_22 f_600 c_white bc_green">Register</button>
                        </div>

                    </div>

                </div>
            </div>
            <div>

            </div>
        </div>
    )
}