import "./style.css";
import Header from "../../components/header/Header";
import home_back from "../../assets/images/home_background_image.png";
import services_back from "../../assets/images/services_back.png";
import brown_pic from "../../assets/images/Rectangle_brown.png";
import green_pic from "../../assets/images/Rectangle_green.png";

export default function Services() {
    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="services_cont">
                    <img src={services_back} alt="services_back_pic" className="services_back_pic"/>
                    <div className="container">
                        <div className="container_inner">
                            <div className="services_content d_flex align_items_center">
                                <div className="services_content_b1">
                                    <h2 className="font_Canela fs_55 f_400 text_left">Get more information about
                                        pricing</h2>
                                    <div className="fs_22 f_500 text_left margin_top_16">
                                        Lorem ipsum dolor sit amet consectetur. Auctor libero egestas sit id. Rhoncus
                                        bibendum varius velit a et. Nec eget lacus semper aliquam.
                                    </div>
                                    <div className="fs_22 f_500 text_left">
                                        Lorem ipsum dolor sit amet consectetur. Sit ultrices tellus eu tortor iaculis
                                        amet. Nec mauris at pharetra nec massa curabitur dolor morbi. Amet leo donec ut
                                        maecenas. Faucibus ac habitant arcu molestie adipiscing adipiscing ultricies
                                        maecenas.
                                    </div>
                                </div>
                                <div className="services_content_b2 d_flex align_items_center justify_content_space_between">
                                    <div className="services_content_b2_1 text_left">
                                        <img src={brown_pic} alt="brown_pic" className="brown_pic"/>
                                        <p className="fs_36 f_600 c_darkBlue price">$ 50</p>
                                        <p className="c_darkBlue fs_28 f_600">Mountly</p>
                                        <ul className="margin_top_16">
                                            <li className="f_500">Emergency</li>
                                            <li className="f_500">Vet Visits</li>
                                            <li className="f_500">Vitamins</li>
                                            <li className="f_500">Emergency</li>
                                            <li className="f_500">Emergency</li>
                                            <li className="f_500">Emergency</li>
                                        </ul>
                                        <span  className="c_white f_500 choose_plan">CHOOSE PLAN</span>
                                    </div>

                                        <div className="services_content_b2_2 text_left">
                                            <img src={green_pic} alt="brown_pic" className="brown_pic"/>
                                            <p className="fs_36 f_600 c_darkBlue price">$ 1000</p>
                                            <p className="c_darkBlue fs_28 f_600">Mountly</p>
                                            <ul className="margin_top_16">
                                                <li className="f_500">Emergency</li>
                                                <li className="f_500">Vet Visits</li>
                                                <li className="f_500">Vitamins</li>
                                                <li className="f_500">Emergency</li>
                                                <li className="f_500">Emergency</li>
                                                <li className="f_500">Emergency</li>
                                            </ul>
                                            <span className="c_white f_500 choose_plan">CHOOSE PLAN</span>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}