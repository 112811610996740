import "./style.css";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import logo from "../../assets/images/Logo.png";

export default function Header() {
    let location = useLocation()
    let navigate = useNavigate()
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");

    const LogoutUser = () => {
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("jwtToken")
        localStorage.removeItem("token")
        navigate("/login")
        console.log(token);
        // axios.get(`${config.url}/auth/logout`, {
        //     headers: {
        //         'authorization': `${token}`
        //     }
        // })
        //     .then(response => {
        //         console.log(response, "logout");
        //         localStorage.removeItem("refreshToken")
        //         localStorage.removeItem("jwtToken")
        //         localStorage.removeItem("token")
        //         navigate("/login")
        //     })
        //     .catch(error => {
        //         console.log(error.response, "elq")
        //     })
    }

    return (
        <>
            {
                !token ?
                    <header className="header_cont d_flex justify_content_space_between align_items_center">
                        <div onClick={() => navigate("/")}>
                            <img src={logo} alt="logo" className="logo"/>
                        </div>
                        <div className="d_flex align_items_center justify_content_space_between header_navlinks_cont">
                            <div className="d-flex align-items-center justify-content-space-between">
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active fs_16 f_600 c_green' : 'header_navLink fs_16 f_400'}
                                    to="/">Home</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active fs_16 f_600 c_green' : 'header_navLink fs_16 f_400'}
                                    to="/about">About</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active fs_16 f_600 c_green' : 'header_navLink fs_16 f_400'}
                                    to="/services">Services</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active fs_16 f_600 c_green' : 'header_navLink fs_16 f_400'}
                                    to="/login">Login</NavLink>
                            </div>
                        </div>
                    </header>
               :
                    <header className="header_cont d_flex justify_content_space_between align_items_center">
                        <div onClick={() => navigate("_1")}>
                            <img src={logo} alt="logo" className="logo"/>
                        </div>
                        <div className="d_flex align_items_center justify_content_space_between header_navlinks_cont">
                            <div className="d-flex align-items-center justify-content-space-between">
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active fs_16 f_600 c_green' : 'header_navLink fs_16 f_400'}
                                    to="/home/_1">Home</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active fs_16 f_600 c_green' : 'header_navLink fs_16 f_400'}
                                    to="/about">About</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active fs_16 f_600 c_green' : 'header_navLink fs_16 f_400'}
                                    to="/services">Services</NavLink>
                                <button  className='header_navLink fs_16 f_400' onClick={LogoutUser}>Logout</button>
                            </div>
                        </div>
                    </header>
            }
        </>
    )
}