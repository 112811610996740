import Header from "../../components/header/Header";
import login_pic from "../../assets/images/login_pic.png";
import mail_icon from "../../assets/images/icons/mail_icon.png";
import block_icon from "../../assets/images/icons/block_icon.png";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import config from "../../config";
import axios from "axios";

export default function ResetPassword() {
    let navigate = useNavigate();
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");
    let [show, setShow] = useState(false)
    const [reset, setReset] = useState({
        password: "",
        confirm_password:""
    })
    const [error, setError] = useState("")
    const [resetBtn, setResetBtn] = useState(false)
    const handleChange = (e, name) => {
        setReset({...reset, [name]: e})
        setError("")
    }
    const ResetPassHandler = () => {
        let values={
            password: reset.password,
            confirm_password:reset.confirm_password,
        }
        navigate("/login")
        setResetBtn(true)
        // if (login.email.length && login.password.length) {
        //     if (login.password.length > 7) {
        //         if (validateEmail()) {
        //             axios.post(`${config.url}/v1/token`,values )
        //                 .then(response => {
        //                     console.log(response,"response");
        //                     localStorage.setItem('jwtToken',  response.data.token)
        //                     navigate("/login/reset_password")
        //                 })
        //                 .catch(error => {
        //                     console.log(error.response,"error")
        //                     setError(error.response.data.error)
        //                 })
        //         } else {
        //             setError("Incorrect email address")
        //         }
        //     } else {
        //         setError("Password length is small")
        //     }
        // }
    }


    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="Login_cont d_flex align_items_center justify_content_space_between">
                    <div className="Login_cont_b1">
                        <img src={login_pic} alt="pic" className="login_pic"/>
                    </div>
                    <div className="Login_cont_b2 d_flex fd_column">
                        <p className="font_Canela fs_55 f_700 text_left">Reset Password</p>
                        <div className="d_flex fd_column inputs_b">
                            <div className="input_b margin_top_16">
                                <img src={block_icon} alt="block_icon"/>
                                <input type="password" placeholder="Password"
                                       style={!reset.password && resetBtn ? {border: "1px solid red"} : null}
                                       onChange={(e) => handleChange(e.target.value, "password")}
                                />
                            </div>
                            <div className="input_b margin_top_16">
                                <img src={block_icon} alt="block_icon"/>
                                <input type="password" placeholder="Confirm Password"
                                       style={!reset.confirm_password && resetBtn ? {border: "1px solid red"} : null}
                                       onChange={(e) => handleChange(e.target.value, "confirm_password")}
                                />
                            </div>
                        </div>
                        {error ? <p className="error">{error}</p> : null}
                        <div className="bc_green login_btn_b">
                            <button className="fs_22 f_600 c_white bc_green"  onClick={ResetPassHandler}>Reset Password</button>
                        </div>
                    </div>

                </div>
            </div>
            <div>

            </div>
        </div>
    )
}