import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Login from "./pages/Login/Login";
import PrivateRoute from "./components/routing/PrivateRoute";
import Register from "./pages/Register/Register";
import HomeStep1 from "./pages/HomeSteps/HomeStep1/HomeStep1";
import HomeStep2 from "./pages/HomeSteps/HomeStep2/HomeStep2";
import HomeSteps from "./pages/HomeSteps/HomeSteps";
import HomeStep3 from "./pages/HomeSteps/HomeStep3/HomeStep3";
import HomeStep4 from "./pages/HomeSteps/HomeStep4/HomeStep4";
import HomeStep5 from "./pages/HomeSteps/HomeStep5/HomeStep5";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import HomeStep6 from "./pages/HomeSteps/HomeStep6/HomeStep6";



function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/login/forget_password"  element={<ForgotPassword />}/>
                    <Route path="/login/reset_password"  element={<ResetPassword />}/>

                    <Route element={<PrivateRoute/>}>
                        <Route path="/home" element={<HomeSteps/>}>
                            <Route index path="_1"  element={<HomeStep1/>}/>
                            <Route path="_2"  element={<HomeStep2/>}/>
                            <Route path="_3"  element={<HomeStep3/>}/>
                            <Route path="_4"  element={<HomeStep4/>}/>
                            <Route path="_5"  element={<HomeStep5/>}/>
                            <Route path="_6"  element={<HomeStep6/>}/>
                            {/*<Route path="_3" element={<HomeStep3/>}/>*/}
                            {/*<Route path="*" element={<Navigate to="/companies_created" replace />} />*/}
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
