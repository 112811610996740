import Header from "../../../components/header/Header";
import back_pic from "../../../assets/images/HomePage_pic.png";
import Union from "../../../assets/images/Union.png";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import back_pic_cat from "../../../assets/images/HomePage_pic_back_cat.png";

export default function HomeStep6() {
    let navigate=useNavigate()
    let {state}=useLocation()
    const optionsMonth = [
        {
            value: '',
            text: '01',
            id: 0,
        },
        {
            value: '01',
            text: '01',
            id: 1,
        },
        {
            value: '02',
            text: '02',
            id: 2,
        },
        {
            value: '03',
            text: '03',
            id: 3,
        },
        {
            value: '04',
            text: '04',
            id: 4,
        },
        {
            value: '05',
            text: '05',
            id: 5,
        },
        {
            value: '06',
            text: '06',
            id: 6,
        },
        {
            value: '07',
            text: '07',
            id: 7,
        },
        {
            value: '08',
            text: '08',
            id: 8,
        },
        {
            value: '09',
            text: '09',
            id: 9,
        },
        {
            value: '02',
            text: '02',
            id: 2,
        },
        {
            value: '02',
            text: '02',
            id: 2,
        },

    ];
    let [petBread, setPetBread] = useState(optionsMonth[0].value)
    const [error, setError] = useState("")

    const handleChange = event => {
        console.log(event.target.value);
        setPetBread(event.target.value);
        setError("")
    };


    let _handleContinue = () => {
        if (petBread.length) {
            navigate("/home/_7", {
                state: {
                    animal_name: state.animal_name,
                    img_key: state.img_key  ? 'cat' : 'dog',
                }
            })
            console.log("next step")
        } else {
            setError("Please fill pet's name")
        }

    }
    return (
        <div className="home_cont">
            {state.img_key === 'dog' ?
                <img src={back_pic} alt="photo" className="home_back"/>
                :
                <img src={back_pic_cat} alt="photo" className="home_back"/>
            }
            <div className="home_page2_container">
                <div className="container">
                    <div className="container_inner">
                        <div className="home_page_content">
                            <div className="d_flex fd_column align_items_start justify_content_start">
                                <p className="fs_55 font_Canela c_brown home_page_content_p">
                                    What is {state.animal_name}'s birthday?
                                </p>
                                <div className="d_flex fd_column justify_content_center align_items_center home_page_content_span">
                                    <span className="text_center fs_18 f_500">If you don’t know, use your best guess..</span>
                                </div>

                                <div className="d_flex align_items_center pet_btns_b justify_content_space_between">
                                    <div className="home_page_content_input_b">
                                        <select value={petBread} className="fs_22 c_blue" onChange={handleChange}>
                                            {
                                                optionsMonth.map((option, index) => {
                                                    return (
                                                        <option key={option.value}
                                                                value={option.value}>{option.text}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}