import "./style.css";
import {useNavigate} from "react-router-dom";
import Header from "../../../components/header/Header";
import back_pic from "../../../assets/images/HomePage_pic.png";
import {useState} from "react";
import Union from "../../../assets/images/Union.png";

export default function HomeStep1() {
    let navigate=useNavigate()
    const [boxActive, setBoxActive] = useState(null)
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");
    let [box,setBox]=useState([
        {
            title:"Fleas, Ticks, & Heartworms",
            desc:"Scratching, licking or biting, visible fleas, coughing, lethargy",
            id: 1,
        },
        {
            title:"Allergies, Itching, & Skin",
            desc:"Excessive itching, redness of skin, scabbing, hair loss",
            id: 2,
        },
        {
            title:"Stress & Anxiety",
            desc:"Destructive behavior, restlessness, excessive panting",
            id: 3,
        },
        {
            title:"Ear Infection",
            desc:"Redness in ear, scratching at ear, discharge from ear",
            id: 4,
        },
        {
            title:"Urinary or UTI",
            desc:"Difficulty urinating, strong odor, blood in urine",
            id: 5,
        },
        {
            title:"Eye Infection",
            desc:"Blinking excessively, rubbing at eye, discharge from eye",
            id: 6,
        },
        {
            title:"Prescriptions & Refills",
            desc:"Treatment, medication, prescriptions, and refills",
            id: 7,
        },
        {
            title:"Digestive Issues",
            desc:"Loss of appetite, diarrhea, vomiting",
            id: 8,
        },
    ])
    return (
        <div>
                <div className="home_cont">
                    <img src={back_pic} alt="photo" className="home_back"/>
                    <div className="home_page_container">
                        <div className="container">
                            <div className="container_inner">
                                <div className="home_page_content ">
                                    <div className="home_page_content1 d_flex fd_column justify_content_center align_items_center">
                                        <div className="d_flex fd_column align_items_start">
                                            <div className="d_flex align_items_center f_700">
                                                <p className="fs_55 font_Canela c_brown f_700"> Our online vets are here to help</p>
                                            </div>
                                        </div>
                                        <span className="text_center fs_18 f_500 margin_top_16">Please select your concern below:</span>
                                    </div>
                                    <div className="home_page_content_blocks d_grid grid_columns_2fr">
                                        {
                                             box.map((item,index)=>{
                                                 return (
                                                     <div className={boxActive===index || index===1 ? "home_page_content_block_active d_flex fd_column justify_content_start align_items_start": "home_page_content_block d_flex fd_column justify_content_start align_items_start"}
                                                          onClick={() => {
                                                                      setBoxActive(index)
                                                                      navigate("/home/_2")
                                                                      console.log(index,"index")
                                                          }}>
                                                         <span className="fs_18 f_600 text_left">{item.title}</span>
                                                         <div className="fs_14 f_7400 text_left">{item.desc}</div>
                                                     </div>
                                                 )
                                        })
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            // <div>
            //
            // </div>
        // </div>
    )
}